import React from "react"
import Layout from "../components/Layout"

const AboutPage = () => (
  <>
    <Layout>
      <h1>About Me</h1>
      <p>Placeholder</p>
      
    </Layout>
  </>
)

export default AboutPage
